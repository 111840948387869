import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { PDFDocument } from "pdf-lib";

import Papa from 'papaparse';

const Main = (props) => {

    console.log(props)

    const [loadedData, setLoadedData] = useState([])

    const isCancelled = useRef(false);

    const handleCancelFetch = () => {
        isCancelled.current = true;
    };

    function addCommas(number) {
        // Convert the number to a string and use a regular expression to add commas
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function transformInputDate(inputDate) {
        // Parse the input date string
        const date = new Date(inputDate);
      
        // Extract the year, month, and day from the input date
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
        const day = date.getUTCDate();
      
        // Format the month and day to ensure they are in 'MM' or 'DD' format
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
      
        // Construct the new date string
        // If you intended to literally use '2123' as the year, replace 'year' with '2123' in the next line
        const newDateStr = `${year}-${formattedMonth}-${formattedDay}`;
      
        return newDateStr;
      }
      
    
    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const downloadCSV = async (data, keys, filename = 'report.pdf', info, startDate, endDate, fullReport, agentName) => {
        console.log(data, keys, filename, info)
        data = data.map((table) => {
            return transposeArray(table)
        })
        info = info.map((table) => {
            return {
                admin_name: table?.admin_ID?.name,
                report_type: table?.report_subtype,
                total_contract_count: table?.total_contract_count,
                total_net: table?.total_net,
                total_contract_gross: table?.total_contract_gross,
                total_spiffee_payout: table?.total_spiffee_payout,
            }
        })
        console.log(data, keys, filename, info)
        return new Promise((resolve, reject) => {
            resolve(exportToCsv(agentName + " - " + startDate + " - " + endDate + " Combined Subagent Report.csv", info, data, startDate, endDate, fullReport, agentName))
        })
    }

    const exportToCsv = async (filename, admins, reports, startDate, endDate, fullReport, agentName) => {
        // Merge admin data with report data
        const mergedData = admins.map((admin, i) => ({
            ...admin,
            details: reports[i]
        }));

        console.log(mergedData)
    
        // Remove all commas from merged data
        mergedData.forEach(adminData => {
            Object.keys(adminData).forEach(key => {
                if (typeof adminData[key] === 'string') {
                    adminData[key] = adminData[key].replace(/,/g, ''); // Remove commas from strings
                }
            });
    
            // Remove commas from details data
            adminData.details = adminData.details.map(detail =>
                detail.map(item => (typeof item === 'string' ? item.replace(/,/g, '') : item))
            );
        });
    
        // Create CSV content
        let csvContent = "";
    
        // Add Summary section
        csvContent += ("Summary" + ' - ' + agentName +  ' (' + startDate + ' - ' + endDate + ')\n');
        let totalNetSum = 0;
        let totalContractCountSum = 0;
    
        mergedData.forEach((adminData) => {
            const { admin_name, report_type, total_net, total_contract_count } = adminData;
            csvContent += `${admin_name},${report_type},${total_net},${total_contract_count}\r\n`;
            totalNetSum += total_net;
            totalContractCountSum += total_contract_count;
        });
    
        // Add Total row
        csvContent += `total,,${totalNetSum.toFixed(2)},${totalContractCountSum.toFixed(2)}\r\n\r\n`;
    
        console.log(mergedData);
    
        mergedData.forEach((adminData) => {
            // Add Admin title
            csvContent += `${adminData.admin_name} - ${adminData.report_type}\r\n`;
            // Add summary headers
            csvContent += "Gross,Spiffs,Net,Contract Count\r\n";
            // Add Summary data
            csvContent += `${adminData.total_contract_gross},${adminData.total_spiffee_payout},${adminData.total_net},${adminData.total_contract_count}\r\n`;
            // Add Headers
            csvContent += "Dealer,Contract Type,Gross/c,Spiffs/c,Net/c,CC/c,QTY,Total Net,Total CC,Unique Identifier\r\n";
            // Add report data
            adminData.details.forEach((detail) => {
                csvContent += detail.join(",") + "\r\n";
            });
            // Add a newline to separate sections
            csvContent += "\r\n";
        });
    
        // Create a blob with the CSV content
        return new Promise((resolve, reject) => {
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            resolve(blob)
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                // Create a link element and download the CSV file
                const link = document.createElement("a");
                if (link.download !== undefined) { // feature detection for download attribute
                    const url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    if (fullReport)
                    {
                    }
                    else
                    {
                        link.click();
                    }
                    document.body.removeChild(link);
                }
            }
        })

    }
    

    const downloadPDF = async (data, keys, filename = 'report.pdf', info, startDate, endDate, fullReport, agentName) => {
        console.log(data, keys, filename, info)
        data = data.map((table) => {
            return transposeArray(table)
        })

        console.log(info)

        info = info.map((table) => {
            return {
                admin_name: table?.admin_ID?.name === '' ? 'No Admin':table?.admin_ID?.name,
                report_type: table?.report_subtype,
                total_contract_count: table?.total_contract_count,
                total_net: table?.total_net,
                total_contract_gross: table?.total_contract_gross,
                total_spiffee_payout: table?.total_spiffee_payout,
                total_dppp_amount: table?.total_dppp_amount,
                total_inside_sales_payout: table?.total_inside_sales_payout,
                total_gapp_amount: table?.total_gapp_amount,
            }
        })

        // resolveReportIDs(filename, info, data, startDate, endDate, fullReport)

        return new Promise((resolve, reject) => {
            resolve(resolveReportIDs(filename, info, data, startDate, endDate, fullReport, agentName))
        })
    }

    const resolveReportIDs = async (filename, admins, reports, startDate, endDate, fullReport, agentName) => {

        // Create a new jsPDF instance
        const doc = new jsPDF({orientation: 'landscape'});
    
        const mergedData = admins.map((admin, i) => ({
            ...admin,
            details: reports[i]
        }));
    
        console.log(mergedData);

        let reportCodeList = []

        mergedData.map(table => {
            table.details.map(report => {
                reportCodeList.push(report[9])
            })
        })
    
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(reportCodeList)
        };
    
        // Wrapping the API request inside a promise
        const fetchReportData = () => {
            return new Promise((resolve, reject) => {
                props.tokenSafeAPIRequest(`/report/full`, requestOptions, '', (result) => {
                    if (result) {
                        console.log(result)
                        matchCodesToReports(result)
                        resolve(result);
                    } else {
                        reject('Error fetching report data');
                    }
                });
            });
        };

        const matchCodesToReports = (reportCodeListResponse) => {
            mergedData.map(table => {
                table.details.map(report => {
                    reportCodeListResponse.map(resolvedReport => {
                        if (report[9] === resolvedReport.code)
                        {
                            let tempSpiffs = ""
                            let tempReserveInputs = ""
                            let tempFlats = ""
                            let tempSplits = ""
                            resolvedReport.spiffs.map((spiff) => {
                                tempSpiffs = tempSpiffs + ' | ' + (spiff.dealership_spiff ? spiff.dealership_ID.name:spiff.spiffee_ID.name) + ' - $' + spiff.amount.toFixed(2)
                            })
                            resolvedReport.reserve_inputs.map((input) => {
                                tempReserveInputs = tempReserveInputs + ' | ' + input.account_ID.name + ' - $' + input.amount.toFixed(2)
                            })
                            resolvedReport.flats.map(flat => {
                                tempFlats = tempFlats + ' | ' + flat.user_ID.first_name + ' ' + flat.user_ID.last_name + " - $" + flat.amount.toFixed(2)
                            })
                            resolvedReport?.profit_splits?.map(split => {
                                tempSplits = tempSplits + " | " + split.user_ID.first_name + ' ' + split.user_ID.last_name + " - $" + split.net.toFixed(2)
                            })
                            report[9] = tempSpiffs; // Assuming you need the date
                            report[10] = tempReserveInputs;
                            report[11] = tempFlats
                            report[12] = tempSplits
                            report[13] = resolvedReport.dppp_amount
                            report[14] = resolvedReport.gap_amount
                            report[15] = resolvedReport.inside_sales_payout
                            report[16] = resolvedReport.reason
                        }
                    })
                })
            })
        }

        // Call fetchReportData and chain the promise to trigger buildPDF once the data is fetched
        return new Promise((resolve, reject) => {
            fetchReportData()
            .then(() => {
                buildPDF(); // Trigger the buildPDF function once the API request completes
            })
            .catch((error) => {
                console.error(error);
            });
        
            // This function is called once all promises are resolved
            const buildPDF = () => {
                console.log(props)
    
                const addPageNumbers = (doc) => {
                    const pageSize = doc.internal.pageSize;
                    const pageHeight = pageSize.height;
                    const pageWidth = pageSize.width;
            
                    // Add footer with page number
                    const pageCount = doc.internal.getNumberOfPages();
                    for (let i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.text(
                            `Page ${i}`,
                            pageWidth - 30,
                            pageHeight - 10
                        );
                    }
                };
                // Initialize the PDF in landscape mode
                const doc = new jsPDF({ orientation: 'landscape' });
                let pageCount = 1; // Start page numbering from 1
            
                // Add Summary section
                let totalNetSum = 0;
                let totalContractCountSum = 0;
                const summaryRows = [];
    
                console.log(mergedData)
            
                mergedData.forEach((adminData) => {
                    // Prepend $ to total_net
                    summaryRows.push([
                        adminData.admin_name, 
                        adminData.report_type, 
                        `$${addCommas(adminData.total_net.toFixed(2))}`, 
                        `${addCommas(adminData.total_contract_count.toFixed(2))}`
                    ]);
                    totalNetSum += adminData.total_net;
                    totalContractCountSum += adminData.total_contract_count;
                });
            
                // Add header for summary with agent's name
                doc.text(`Agent: ${agentName} | ${startDate} - ${endDate}`, 14, 10);
                doc.text(`${'Summary'}`, 14, 17.5);
            
                // Add Summary table with light grey border and no row fill color
                doc.autoTable({
                    startY: 20,
                    head: [['Admin Name', 'Report Type', 'Total Net', 'Total Contract Count']],
                    body: summaryRows,
                    styles: {
                        lineColor: [200, 200, 200], // Light grey border lines
                        lineWidth: 0.1             // Thin border lines
                    },
                    bodyStyles: {
                        fillColor: false             // Disable row fill color completely
                    },
                    headStyles: {
                        fillColor: false,            // No background for header row
                        textColor: 0,                // Black text
                        lineColor: [200, 200, 200],  // Light grey border lines for header
                        lineWidth: 0.1
                    },
                    tableLineColor: [200, 200, 200], // Light grey border around table
                    tableLineWidth: 0.1,
                    willDrawCell: function(data) {
                        data.cell.styles.fillColor = false; // Override alternating row colors
                    }
                });
            
                // Add Total row with $ in front of Total Net
                doc.text(`Total Net: $${totalNetSum.toFixed(2)}`, 14, doc.lastAutoTable.finalY + 10);
                doc.text(`Total Contract Count: ${totalContractCountSum.toFixed(2)}`, 14, doc.lastAutoTable.finalY + 16);
            
                // Increment page count after the first page
                pageCount++;
    
                console.log(mergedData)
    
                // For each adminData, add their details in a new landscape page
                mergedData.forEach((adminData) => {
                    doc.addPage('landscape');  // Ensure each page added is landscape
            
                    // Add agent's name to the top of each page
                    doc.text(`Agent: ${agentName} | ${startDate} - ${endDate}`, 14, 10);
            
                    doc.text(`${adminData.admin_name === undefined ? 'No Admin' : adminData.admin_name} - ${adminData.report_type}`, 14, 17.5);
                    doc.text(`${'Summary'}`, 14, 25);
            
                    // Prepend $ to the values in the table body
                    doc.autoTable({
                        startY: 30,
                        head: [['Gross', 'Spiffs', 'Net', 'Contract Count','DPPP','GAP Plus', 'IS']],
                        body: [[
                            `$${addCommas(adminData.total_contract_gross.toFixed(2))}`, 
                            `$${addCommas(adminData.total_spiffee_payout.toFixed(2))}`, 
                            `$${addCommas(adminData.total_net.toFixed(2))}`, 
                            `${addCommas(adminData.total_contract_count.toFixed(2))}`,
                            `$${addCommas(adminData.total_dppp_amount.toFixed(2))}`, 
                            `$${addCommas(adminData.total_gapp_amount.toFixed(2))}`, 
                            `$${addCommas(adminData.total_inside_sales_payout.toFixed(2))}`, 
                        ]],
                        styles: {
                            lineColor: [200, 200, 200], // Light grey border lines
                            lineWidth: 0.1              // Thin border lines
                        },
                        bodyStyles: {
                            fillColor: [0, 0, 0],             // Disable row fill color completely
                        },
                        headStyles: {
                            fillColor: false,            // No background for header row
                            textColor: 0,                // Black text
                            lineColor: [200, 200, 200],  // Light grey border lines for header
                            lineWidth: 0.1
                        },
                        tableLineColor: [200, 200, 200], // Light grey border around table
                        tableLineWidth: 0.1,
                        willDrawCell: function(data) {
                            data.cell.styles.fillColor = false; // Override alternating row colors
                        }
                    });
            
                    doc.text(`${'Reports'}`, 14, 55);
            
                    // Prepare data for table with $ in Gross/c, Spiffs/c, Net/c, and Total Net
                    const tableHead = [['Dealer', 'Contract Type', 'Gross/c', 'Spiffs/c', 'Net/c', 'CC/c', 'QTY', 'Total Net', 'Total CC', 'Spiffs/c', 'Reserve Inputs/c', 'Flats/c', 'Splits/c', 'DPPP/c', 'GAP/c', 'IS/c', 'Reason']];
                    const tableBody = adminData.details.map(detail => [
                        detail[0],  // Dealer
                        detail[1],  // Contract Type
                        `$${addCommas(parseFloat(detail[2]).toFixed(2))}`,  // Gross/c
                        `$${addCommas(parseFloat(detail[3]).toFixed(2))}`,  // Spiffs/c
                        `$${addCommas(parseFloat(detail[4]).toFixed(2))}`,  // Net/c
                        `${addCommas(parseFloat(detail[5]).toFixed(2))}`,  // CC/c
                        detail[6],  // QTY
                        `$${addCommas(parseFloat(detail[7]).toFixed(2))}`,  // Total Net
                        `${addCommas(parseFloat(detail[8]).toFixed(2))}`,  // Total CC
                        detail[9],  // Spiffs
                        detail[10],  // Reserve Inputs
                        detail[11],
                        detail[12],
                        `$${addCommas(detail[13].toFixed(2))}`,
                        `$${addCommas(detail[14].toFixed(2))}`,
                        `$${addCommas(detail[15].toFixed(2))}`,
                        detail[16]
                    ]);
            
                    // Render the detailed report table with custom borders and no row fill
                    doc.autoTable({
                        startY: 60,
                        head: tableHead,
                        body: tableBody,
                        styles: {
                            lineColor: [200, 200, 200], // Light grey border lines
                            lineWidth: 0.1,              // Thin border lines
                            fillColor: false,
                        },
                        bodyStyles: {
                            fillColor: false             // Disable row fill color completely
                        },
                        headStyles: {
                            fillColor: false,            // No background for header row
                            textColor: 0,                // Black text
                            lineColor: [200, 200, 200],  // Light grey border lines for header
                            lineWidth: 0.1
                        },
                        tableLineColor: [200, 200, 200], // Light grey border around table
                        tableLineWidth: 0.1,
                        columnStyles: {
                            16: { maxCellWidth: 60, minCellWidth: 15 }, // Set the "Reason" column (index 16) to have a width of 300 pixels
                            0: { cellWidth: 15 }, // Example: Set minimum width for "Dealer" column
                            1: { minCellWidth: 15 }, // Example: Set minimum width for "Contract Type" column
                            2: { minCellWidth: 15 },
                            3: { minCellWidth: 15 },
                            4: { minCellWidth: 15 },
                            5: { minCellWidth: 15 },
                            6: { minCellWidth: 15 },
                            7: { minCellWidth: 15 },
                            8: { minCellWidth: 15 },
                            9: { maxCellWidth: 45, minCellWidth: 15 },
                            10: { maxCellWidth: 45, minCellWidth: 15 },
                            11: { maxCellWidth: 45, minCellWidth: 15 },
                            12: { minCellWidth: 15 },
                            13: { minCellWidth: 15 },
                            14: { minCellWidth: 15 },
                            15: { minCellWidth: 15 },
                        },
                        willDrawCell: function(data) {
                            data.cell.styles.fillColor = false; // Override alternating row colors
                        }
                    });
                });
    
                addPageNumbers(doc);
            
                // Save the PDF
                if (fullReport)
                {
                    resolve(doc)
                }
                else
                {
                    doc.save(filename + ' - ' + agentName + ' - ' + startDate + ' - ' + endDate);
                }
                resolve(6)
            };   
        })                   
    };

    const [downloadState, setDownloadState] = useState(0)

    const downloadAllPDF = async () => {
        console.log(props.agents);
        setDownloadState('Downloading...')

        function asyncMapWithPromises(array, asyncCallback) {
            return array.reduce((promiseChain, item) => {
                return promiseChain.then(results =>
                    asyncCallback(item).then(result => [...results, result])
                );
            }, Promise.resolve([])); // Start with a resolved promise containing an empty array
        }

        async function mergeAndSaveJSPDFArray(docs, outputFilename = "merged.pdf") {
            try {
              // Create a new PDF document with pdf-lib
              const mergedPdf = await PDFDocument.create();
          
              // Iterate over the array of jsPDF documents
              for (const doc of docs) {
                // Convert the jsPDF document to an ArrayBuffer
                const docBytes = doc.output("arraybuffer");
          
                // Load the jsPDF document into pdf-lib's PDFDocument
                const pdf = await PDFDocument.load(docBytes);
          
                // Copy all pages from the current document into the merged PDF
                const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                pages.forEach((page) => mergedPdf.addPage(page));
              }
          
              // Serialize the merged PDF to bytes
              const mergedPdfBytes = await mergedPdf.save();
          
              // Create a Blob and trigger download
              const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = outputFilename;
              link.click();
              setDownloadState('Download Completed')
            } catch (error) {
              console.error("Error merging jsPDF documents:", error);
            }
        }
           
        const delay = (ms, value) => new Promise(resolve => setTimeout(() => resolve(value), ms));

        function removeItemsWithEmptyFirstArray(items) {
            // Filter the items array to exclude items with an empty first array
            return items.filter(item => Array.isArray(item[0]) && item[0].length > 0);
        }

        asyncMapWithPromises(props.agents, agent => {
            return delay(100, getAgentData(agent.value));
        }).then(results => {
            console.log(results); // [2, 4, 6]
            asyncMapWithPromises(removeItemsWithEmptyFirstArray(results), data => {
                setLoadedData((loadedData) => [loadedData, ['']])
                return delay(100, downloadPDF(data[0], props.exportKeys, props.fileTitle, data[1].admin_report_info, props.startDate, props.endDate, true, data[1].subagent_name))
            }).then(results2 => {
                console.log(results2)
                mergeAndSaveJSPDFArray(results2, "Full Combined Subagent Report - " + props.startDate + ' - ' + props.endDate + ".pdf")
            })
        });
    

    };
    
    const downloadALLCSV = async (docs, outputFilename = "merged.pdf") => {
        console.log(props.agents);

        setDownloadState('Downloading...')

        function asyncMapWithPromises(array, asyncCallback) {
            return array.reduce((promiseChain, item) => {
                return promiseChain.then(results =>
                    asyncCallback(item).then(result => [...results, result])
                );
            }, Promise.resolve([])); // Start with a resolved promise containing an empty array
        }

           
        const delay = (ms, value) => new Promise(resolve => setTimeout(() => resolve(value), ms));

        function removeItemsWithEmptyFirstArray(items) {
            // Filter the items array to exclude items with an empty first array
            return items.filter(item => Array.isArray(item[0]) && item[0].length > 0);
        }

        async function mergeCSVBlobsAndSave(blobs, outputFilename = 'merged.csv') {
            let allData = [];
            let headers = new Set();
        
            for (const blob of blobs) {
                const text = await blob.text(); // Read blob as text
                const parsed = Papa.parse(text, {
                    header: false, // Do not treat the first row as headers
                    skipEmptyLines: false, // Include empty rows
                    dynamicTyping: true, // Automatically convert numeric fields
                });
        
                if (parsed.errors.length > 0) {
                    console.warn(`Error parsing CSV blob:`, parsed.errors);
                }
        
                // Process rows and infer headers based on the longest row
                parsed.data.forEach(row => {
                    if (Array.isArray(row)) {
                        for (let i = 0; i < row.length; i++) {
                            headers.add(`Column${i + 1}`); // Add headers like "Column1", "Column2", etc.
                        }
                    }
                });
        
                allData = allData.concat(parsed.data);
            }
        
            // Convert Set to array for consistent header ordering
            const headersArray = Array.from(headers);
        
            // Normalize all rows to ensure they match the inferred headers
            const normalizedData = allData.map(row => {
                const normalizedRow = {};
                headersArray.forEach((header, index) => {
                    normalizedRow[header] = row[index] !== undefined ? row[index] : ''; // Add missing fields as empty
                });
                return normalizedRow;
            });
        
            // Convert merged data back to CSV
            const mergedCSV = Papa.unparse(normalizedData, { columns: headersArray });
        
            // Convert merged CSV string to Blob
            const mergedBlob = new Blob([mergedCSV], { type: 'text/csv' });
        
            // Create a temporary link element to trigger download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(mergedBlob);
            link.download = outputFilename;
            document.body.appendChild(link);
            link.click();
            setDownloadState('Download Complete')
            document.body.removeChild(link);
        }

        asyncMapWithPromises(props.agents, agent => {
            return delay(100, getAgentData(agent.value));
        }).then(results => {
            console.log(results); // [2, 4, 6]
            asyncMapWithPromises(removeItemsWithEmptyFirstArray(results), data => {
                return delay(100, downloadCSV(data[0], props.exportKeys, props.fileTitle, data[1].admin_report_info, props.startDate, props.endDate, true, data[1].subagent_name))
            }).then(results2 => {
                console.log(results2)
                mergeCSVBlobsAndSave(results2, "Full Combined Subagent Report - " + props.startDate + ' - ' + props.endDate + ".csv")
            })
        });
    }

    const getAgentData = async (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
            
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                subagent_ID: id
            })
        };
    
        let tempTableReportData = [];
    
        return new Promise((resolve, reject) => {
            props.tokenSafeAPIRequest(
                '/metrics/combined_subagent_report?limit=1&skip=0&fetch_links=true&start_date=' + props.startDate + '&end_date=' + props.endDate,
                requestOptions,
                '',
                (result, status) => {
                    if (status !== 200) {
                        reject(new Error('API request failed with status: ' + status));
                        return;
                    }
    
                    let tempData = [[], [], [], []];
    
                    result[0].admin_report_info.map((admin) => {
                        tempData[0].push(admin?.admin_ID?.name === undefined ? 'No Admin' : admin?.admin_ID?.name);
                        tempData[1].push(admin.report_subtype);
                        tempData[2].push(admin.total_net);
                        tempData[3].push(admin.total_contract_count);
                        
                        let tempReportData = [[], [], [], [], [], [], [], [], [], []];
    
                        admin.reports.map((report) => {
                            tempReportData[0].push(report?.dealership_ID?.name);
                            tempReportData[1].push(
                                admin.report_subtype === 'Adjustment'
                                    ? (report.report_type + (report.contract_subtype === null ? '' : ' - ' + report.contract_subtype))
                                    : (report.report_subtype === "Reserve Release" 
                                        ? 'Reserve Release' 
                                        : report.contract_type + ' - ' + report.contract_subtype)
                            );
                            tempReportData[2].push(report.contract_gross);
                            tempReportData[3].push(report.total_spiffee_payout);
                            tempReportData[4].push(report.net);
                            tempReportData[5].push(report.total_contract_count);
                            tempReportData[6].push(report.quantity);
                            tempReportData[7].push(report.net * report.quantity);
                            tempReportData[8].push(report.total_contract_count * report.quantity);
                            tempReportData[9].push(report.code);
                        });
    
                        tempTableReportData.push(tempReportData);
                    });
    
                    console.log(tempTableReportData);
    
                    tempData[0].push('Total');
                    tempData[1].push('');
                    tempData[2].push(result[0].total_net);
                    tempData[3].push(result[0].total_contract_count);
    
                    resolve([tempTableReportData, result[0]]);
                }
            );
        });
    };
    
    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Export Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={() => {handleCancelFetch(); props.setIsOpen()}} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={() => {handleCancelFetch(); props.setIsOpen()}}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <span style={{fontWeight: '500', fontSize:'16px'}}>Downloaded Rows: {downloadState === 0 ? loadedData.length:downloadState}</span>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadCSV(props.reportData, props.exportKeys, props.fileTitle, props.admin_report_info, props.startDate, props.endDate, false, props.selectedAgent.subagent_name)}>Download CSV</Button>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadALLCSV()}>Download All Agents CSV</Button>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadPDF(props.reportData, props.exportKeys, props.fileTitle, props.admin_report_info, props.startDate, props.endDate, false, props.selectedAgent.subagent_name)}>Download PDF</Button>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadAllPDF()}>Download All Agents PDF</Button>
            </div>
        </div>
    )
}

export default Main
