import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link, useLocation } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import Dropdown from '../elements/ReactSelectDropdown'

import ModalManager from '../elements/ModalManager'
import FiltersModal from '../assets/metrics/FiltersModal'

import ExportData from '../assets/metrics/ExportData'

import ImportOutputModal from '../assets/metrics/ViewInputOutputReport'
import ViewOutputModal from '../assets/metrics/ViewOutputReport'

import Notification from '../elements/Notification'

import CalendarModal from '../assets/metrics/CalendarModal'

import { useCookies } from 'react-cookie'

import WeekSelector from '../elements/WeekSelector'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const list = ['4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors']

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const accountCode = query.get('accountID');
    const accountName = query.get('accountName')

    useEffect(() => {
        if (accountCode)
        {
            updateSelectedAccount({value: accountCode, label: accountName})
        }
    },[])

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Agent / Spiffee',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Account',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${formatNumber(props.data)}</span>),
            default: '',
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Reason',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'View Report',
            displayElement: (props) => (<Button onClick={() => {props.data.amount > 0 ? viewInputData(props.data):viewOutputData(props.data)}} colors={props.colors} Style={{borderRadius: '5px'}}>View</Button>),
            default: '',
        },
    ])

    const viewInputData = (data) => {
        console.log(data)
        if (data.report_code === null)
        {
            setNotification('No Report for Starting Balance Transaction!')
        }
        else
        {
            setSelectedReport(data);
            setImportOutputModal(true)
        }
    }

    const viewOutputData = (data) => {
        setSelectedReport(data);
        setOutputModal(true)
    }

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
        [tableTitles[8].default],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [allAccounts, setAllAccounts] = useState([])
    const [selectedAccount, setSelectedAccount] = useState({value: 'all', label: 'All'})
    const [refresh, setRefresh] = useState(false)
    const [headerValues, setHeaderValues] = useState([])
    const [skip, setSkip] = useState(0)
    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')
    const [filterData, setFilterData] = useState([
        {
            title: 'Agent',
            data: '',
            value: {label: '', value: ''},
            key: 'user_ID',
        },
        {
            title: 'Spiffee',
            data: '',
            value: {label: '', value: ''},
            key: 'spiffee_ID',
        },
        {
            title: 'Dealership',
            data: '',
            value: {label: '', value: ''},
            key: 'dealership_ID',
        },
        {
            title: 'Type',
            data: [
                {value: true, label: 'Input'},
                {value: false, label: 'Ouput'},
            ],
            value: {label: '', value: ''},
            key: 'input',
        },
    ])

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    useEffect(() => {
        //on page load
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }

        let tempAccountList = []
        let tempDealerList = []
        let tempAdminList = []
        let tempSubagentList = []
        let tempSpiffeeList = []

        //get reserve accounts
        props.tokenSafeAPIRequest('/account/search?limit=9999999999', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            let tempAccountList = []
            tempAccountList.push({value: 'all', label: 'All'})
            result.map((account, index) => {
                tempAccountList.push({value: account.id, label: account.name})
            })
            setAllAccounts(tempAccountList)
        })

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            
        })

        //get admins
        props.tokenSafeAPIRequest('/admin/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })
        })

        //get spiffees
        props.tokenSafeAPIRequest('/spiffee/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            result.map((spiffee, index) => {
                tempSpiffeeList.push({value: spiffee.id, label: spiffee.name})
            })
        })

        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        //get subagents
        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })

        })

        setFilterData([
            {
                title: 'Agent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'user_ID',
            },
            {
                title: 'Spiffee',
                data: tempSpiffeeList,
                value: {label: '', value: ''},
                key: 'spiffee_ID',
            },
            {
                title: 'Dealership',
                data: tempDealerList,
                value: {label: '', value: ''},
                key: 'dealership_ID',
            },
            {
                title: 'Type',
                data: [
                    {value: true, label: 'Input'},
                    {value: false, label: 'Ouput'},
                ],
                value: {label: '', value: ''},
                key: 'input',
            },
        ])

    },[])

    const convertPayeeToUser = (payee, user, spiffee, dealership) => {
        if (payee === null) {return 'No User'}
        if (payee === 0) {return spiffee.name}
        if (payee === 1) {return dealership.name}
        if (payee === 2) {return user === null ? 'No Agent':(user?.first_name + ' ' + user?.last_name)}
        if (payee === 3) {return 'Inside Sales'}
    }

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        //when a account gets selected
        if (selectedAccount.value !== '')
        {
            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            let filterDataBy = {}
            let startDate = ''
            filterData.map((category, index) => {
                if (category.key === 'contract_type')
                {
                    filterDataBy = {
                        ...filterDataBy, 
                        contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                        contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                    }
                }
                else if (category.key === 'date')
                {
                    const today = new Date();
                    const result = new Date(today);
                    if (category.value.value === '')
                    {
                        result.setFullYear(today.getFullYear() - 100);
                    }
                    else
                    {
                        result.setDate(today.getDate() - category.value.value);
                    }
                    startDate = result      
                }
                else
                {
                    filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
                }
            })
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({
                account_ID: (selectedAccount.value === '' || selectedAccount.value === 'all') ? null:selectedAccount.value,
                ...filterDataBy
              })
            }
    
            props.tokenSafeAPIRequest('/metrics/account/' + selectedAccount.value + '?fetch_links=true&limit=15' + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
                console.log(result)

                let tempStats = [
                    {title: 'Total Amount', value: '$' + formatNumber(result.total_amount)},
                    {title: 'Total Input', value: '$' + formatNumber(result.total_inputs)},
                    {title: 'Total Output', value: '$' + formatNumber(result.total_outputs)},
                ]
                setHeaderValues(tempStats)

                let tempTableData = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                result.transactions.map((transfer, index) => {
                    tempTableData[0].push(transfer.quantity)
                    // tempTableData[1].push(transfer.user_ID === null ? transfer.spiffee_ID.name:transfer.user_ID.first_name + ' ' + transfer.user_ID.last_name)
                    tempTableData[1].push(convertPayeeToUser(transfer.payee, transfer.user_ID, transfer.spiffee_ID, transfer.dealership_ID))
                    tempTableData[2].push(transfer.dealership_ID === null ? 'No Dealership':transfer.dealership_ID.name)
                    tempTableData[3].push(transfer.account_ID.name)
                    tempTableData[4].push(transfer.amount > 0 ? 'Input':'Output')
                    tempTableData[5].push(transfer.amount)
                    tempTableData[6].push(formatDate(new Date(transfer.timestamp)))
                    tempTableData[7].push(transfer.reason)
                    tempTableData[8].push(transfer)
                })

                setTableValues(tempTableData)
            })
        }
        setSkip(0)
    },[selectedAccount, filterData, sort, calStartDate, calEndDate])

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const updateSelectedAccount = (agent) => {
        console.log(agent)
        setSelectedAccount(agent)
    }

    const onBottom = () => {
        if (selectedAccount.value !== '')
        {
            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            let filterDataBy = {}
            let startDate = ''
            filterData.map((category, index) => {
                if (category.key === 'contract_type')
                {
                    filterDataBy = {
                        ...filterDataBy, 
                        contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                        contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                    }
                }
                else if (category.key === 'date')
                {
                    const today = new Date();
                    const result = new Date(today);
                    if (category.value.value === '')
                    {
                        result.setFullYear(today.getFullYear() - 100);
                    }
                    else
                    {
                        result.setDate(today.getDate() - category.value.value);
                    }
                    startDate = result      
                }
                else
                {
                    filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
                }
            })
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({
                account_ID: (selectedAccount.value === '' || selectedAccount.value === 'all') ? null:selectedAccount.value,
                ...filterDataBy
              })
            }
    
            props.tokenSafeAPIRequest('/metrics/account/' + selectedAccount.value + '?fetch_links=true&limit=15&skip=' + (skip+15) + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, tokenValues, (result, status) => {
                console.log(result)

                let tempTableData = tableValues

                result.transactions.map((transfer, index) => {
                    tempTableData[0].push(transfer.quantity)
                    tempTableData[1].push(convertPayeeToUser(transfer.payee, transfer.user_ID, transfer.spiffee_ID, transfer.dealership_ID))
                    tempTableData[2].push(transfer.dealership_ID === null ? 'No Dealership':transfer.dealership_ID.name)
                    tempTableData[3].push(transfer.account_ID.name)
                    tempTableData[4].push(transfer.amount > 0 ? 'Input':'Output')
                    tempTableData[5].push(transfer.amount)
                    tempTableData[6].push(formatDate(new Date(transfer.timestamp)))
                    tempTableData[7].push(transfer.reason)
                    tempTableData[8].push(transfer)
                })

                setTableValues(tempTableData)
            })
        }
        setSkip((skip) => skip+15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        admin_ID: '',
        spiffee_ID: '',
        user_ID: '',
        dealership_ID: '',
        input: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        admin_ID: '',
        spiffee_ID: '',
        user_ID: '',
        dealership_ID: '',
        input: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'amount', label: 'Amount' },
        { value: 'timestamp', label: 'Date' },
    ]

    const sortToName = {
        timestamp: 'Date',
        amount: 'Amount',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
        {
            name: 'Prespiff Paid Off',
            link: '/output/prespiffPaidOff',
            key: "PrespiffPaidOff",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'quantity', label: 'QTY'}, {id: 'selected_ID', label: 'Agent/Spiffee'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'account_ID.name', label: 'Account'}, {id: 'input', label: 'Is Input?'}, {id: 'amount', label: 'Amount'}, {id: 'timestamp', label: 'Date'}, {id: 'reason', label: 'Reason'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            account_ID: (selectedAccount.value === '' || selectedAccount.value === 'all') ? null:selectedAccount.value,
            start_date: startDate,
            ...filterDataBy
        }
    }

    const exportPostProcessing = (result) => {
        result.transactions.map((transfer) => {
            let tempTransfer = transfer
            tempTransfer.selected_ID = convertPayeeToUser(transfer.payee, transfer.user_ID, transfer.spiffee_ID, transfer.dealership_ID)
            return tempTransfer
        })
        return result;
    }

    const [importOutputModal, setImportOutputModal] = useState(false)
    const [outputModal, setOutputModal] = useState(false)
    const [ViewSaturdayReport, setViewSaturdayReport] = useState(false)
    const [selectedReport, setSelectedReport] = useState('')

    const [notification, setNotification] = useState('')

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div></div>{/* <OutputColumn colors={props.colors} list={list} title={"Subagents"}></OutputColumn> */}
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Dropdown colors={props.colors} onChange={(e) => updateSelectedAccount(e)} index={props.index} indexParent={props.indexParent} data={allAccounts} width={195} value={selectedAccount.label}>Test</Dropdown>
                    <span onClick={() => console.log(filterData)} style={{fontWeight: '600', marginLeft: '20px', marginTop: '6px'}}>Select an Account</span>
                    <WeekSelector colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} calStartDate={calStartDate} calEndDate={calEndDate} Style={{marginLeft: 'auto', marginRight: '20px'}}>{calStartDate === '2000-01-01' ? '':calStartDate + ' | '} Select Week | {calEndDate === '2200-01-01' ? 'All Times':calEndDate}</WeekSelector>
                </div>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} removeFilter={removeFilter} filterData={filterData} sort={sort} setSort={setSort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <ExportData colors={props.colors} calStartDate={calStartDate} calEndDate={calEndDate} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/account'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Reserve Account Ledger Report'} specific={selectedAccount.value} postProcessing={exportPostProcessing}></ExportData>
                <ImportOutputModal colors={props.colors} isOpen={importOutputModal} setIsOpen={() => setImportOutputModal(false)} selectedReport={selectedReport} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ImportOutputModal>
                <ViewOutputModal colors={props.colors} isOpen={outputModal} setIsOpen={() => setOutputModal(false)} selectedReport={selectedReport} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ViewOutputModal>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </OutputLayoutColumn>
    )
}

export default Main
